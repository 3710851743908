import request from '@/service/lib/request';
// 商品管理

/**
 * 商品-分页
 * @author lb
 */
export function getGoodsPage(data) {
    return request({
      url: '/goods/get_goods_list',
      method: 'post',
      data
    });
}
/**
 * 商品-新增/修改
 * @author lb
 */
export function saveOrUpdateGoods(data) {
  return request({
    url: '/goods/save_or_update_goods',
    method: 'post',
    data
  });
}
/**
 * 商品-删除
 * @author lb
 */
export function deleteGoods(data) {
  return request({
    url: '/goods/delete_goods',
    method: 'post',
    data
  });
}
/**
 * 商品分类-分页
 * @author lb
 */
export function getGoodsLevelPage(data) {
  return request({
    url: '/goods/get_goods_level_list',
    method: 'post',
    data
  });
}
/**
 * 商品分类-全部
 * @author lb
 */
export function getGoodsLevelAll(data) {
  return request({
    url: '/goods/get_goods_level_all',
    method: 'post',
    data
  });
}
/**
 * 商品分类-新增/修改
 * @author lb
 */
export function saveOrUpdateLevel(data) {
  return request({
    url: '/goods/save_or_update_level',
    method: 'post',
    data
  });
}
/**
 * 商品分类-删除
 * @author lb
 */
export function deleteLevel(data) {
  return request({
    url: '/goods/delete_level',
    method: 'post',
    data
  });
}
